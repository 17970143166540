<template>
  <v-dialog v-model="showDialog" persistent>
    <v-card>
      <v-card-title>
        <v-icon large left color="warning">warning</v-icon>
        <span color="warning" class="headline font-weight-bold">Notice</span>
      </v-card-title>
      <v-card-text>
        <v-text-field
          :value="errorType"
          label="Error Type"
          disabled
        ></v-text-field>
        <v-text-field
          :value="errorMessage"
          label="Error Message"
          disabled
        ></v-text-field>
      </v-card-text>
      <v-card-text>
        <v-textarea
          error
          label="Error Stack"
          outline
          readonly
          :value="errorStack"
          v-if="errorStack"
        ></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-btn color="info" dark @click="refresh(false)">Refresh</v-btn>
        <v-btn color="error" dark @click="signOut(false)">Sign Out</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" dark @click="cancel(false)">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from '@/eventBus'

export default {
  name: 'WarningDialog',
  data () {
    return {
      showDialog: false,
      errorMessage: null,
      errorType: null,
      errorStack: null,
    }
  },
  mounted () {
    let vm = this
    EventBus.$on('WarningDialog', function (val) {
      // console.log("warning", val)

      vm.showDialog = val.showDialog
      vm.errorMessage = val.errorMessage
      vm.errorType = val.errorType
      vm.errorStack = val.errorStack
    })
  },
  methods: {
    async cancel(showDialog) {
      console.log('refresh showDialog')

      this.showDialog = showDialog
      // await this.$emit('closeWarningRefresh')
    },
    async refresh(showDialog) {
      console.log('refresh showDialog')

      this.showDialog = showDialog
      await this.$emit('closeWarningRefresh')
    },
    async signOut(showDialog) {
      console.log('signOut showDialog')

      this.showDialog = showDialog
      await this.$emit('closeWarningSignOut')
    }
  }
}
</script>